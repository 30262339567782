import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import HeaderOne from "../components/header-one"
import BannerOne from "../components/banner-one"
import Services from "../components/services"
import FeatureOne from "../components/feature-one"
import FeatureTwo from "../components/feature-two"
import Brands from "../components/brands"
import Faq from "../components/faq"
import BlogHome from "../components/blog-home"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Seo from "../components/seo"
import axios from "axios"
import { GlobalInformationFilter } from "../helper/global_informations"
import { API } from "../config/api"

const Home = () => {
  const [GlobalInformationData, setGlobalInformationData] = useState()
  const [HeaderData, setHeaderData] = useState()
  const [BannerData, setBannerData] = useState()
  const [ServicesData, setServicesData] = useState()
  const [FeatureData, setFeatureData] = useState()
  const [FaqData, setFaqData] = useState()
  const handleGetGlobalInformation = () => {
    const response = axios.get(`${API.URL_ADMIN}/api/v1/news/content-company`).then(response => {
      if (response?.data?.status == true) {
        console.log("GLOBAL INFORMATION : ", response?.data);
        setHeaderData(GlobalInformationFilter(response?.data?.data, 'header'))
        setBannerData(GlobalInformationFilter(response?.data?.data, 'banner'))
        setServicesData(GlobalInformationFilter(response?.data?.data, 'services'))
        setFeatureData(GlobalInformationFilter(response?.data?.data, 'feature'))
        setFaqData(GlobalInformationFilter(response?.data?.data, 'faq'))
        setGlobalInformationData(response?.data?.data)
      }
    }).catch(err => {
      console.error("Something went wrong");
    })

    return response
  }

  console.log("HEADER DATA: ", ServicesData);


  useEffect(() => {
    handleGetGlobalInformation()
  }, [])

  return (
    <Layout pageTitle="Home | Klabbelanja">
      <Seo title="Home | Klabbelanja" />
      <HeaderOne />
      <BannerOne data={HeaderData} />
      {/* <FunFact /> */}
      <Brands data={BannerData} />
      <Services data={ServicesData} />
      <FeatureTwo data={FeatureData} />
      {/* <Pricing /> */}
      {/* <Testimonials /> */}
      <Faq data={FaqData} />
      <BlogHome />
      <Footer />
    </Layout>
  )
}

export default Home
